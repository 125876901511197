import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import crmToolUser from "../images/v2/security/crm-tool-user.svg";
import crmToolRole from "../images/v2/security/crm-tool-role.svg";
import crmToolProfile from "../images/v2/security/crm-tool-profile.svg";
import crmToolPermission from "../images/v2/security/crm-tool-permission.svg";
import alwaysSafeShield from "../images/v2/security/safe-shield.svg";
import securityTFA from "../images/v2/security/security-tfa.svg";
import SecurityImg from "../images/v2/security/Security.svg";
import securityHacker from "../images/v2/security/security-hacker.svg";
import securityRobust from "../images/v2/security/security-robust.svg";
import lightBlueBgImg from "../images/v2/light-blue-bg.svg";
import securityBannerBgImg from "../images/v2/security/banner-background-img.svg";
import testimonialBgImg from "../images/v2/testimonial-bg.svg";
import protectionShield from "../images/v2/security/protection.svg";
import CheckIcon from "@material-ui/icons/Check";
import useWidth from "../hooks/useWidth";
import SEO from "../components/SEO";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "gatsby";
import SingleTestimonial from "../components/v2/HomeSlider/SingleTestimonial";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundImage: "linear-gradient(to right, #e7f2fe, rgba(233, 243, 254, 0.91) 46%, rgba(255, 255, 255, 0))",
  },
  img: {
    maxWidth: "100%",
    height: "auto",
  },
  btn: {
    // minWidth: 307,
    // minHeight: 52,
    // fontWeight: 600,
    // fontSize: 20,
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  lightBlueBg: {
    background: `url(${lightBlueBgImg}) center bottom`,
    backgroundSize: "cover",
  },
  securityBannerBg: {
    background: `url(${securityBannerBgImg}) left center no-repeat`,
    backgroundSize: "cover",
  },
  testimonialCustomerImg: {
    maxWidth: "90%",
    width: "290px",
    borderRadius: "10px",
    boxShadow: "0 15px 50px 0 rgba(0, 0, 0, 0.16)",
    marginTop: "2.65rem",
    marginLeft: "3rem",
  },
  testimonialBg: {
    background: `url(${testimonialBgImg}) no-repeat`,
    backgroundSize: "195px 223px",
  },
  listItemText: {
    "& *": {
      fontSize: 20,
      color: "#2e3f4f",
      [theme.breakpoints.down("xs")]: {
        fontSize: 18,
      },
    },
  },
  listItemIcon: {
    color: "#08a742",
    fontSize: 28,
  },
}));
const productBenefits = [
  {
    title: "User access",
    text: "Establish and provide the appropriate access to the users and remove accesses that are no longer valid. Add, edit, manage your users and map each user to the relevant role and profile.",
    alt: "Secure user access",
    img: crmToolUser,
  },
  {
    title: "Role hierarchy",
    text: (
      <>
        Enforce differential access based on the users’ responsibilities.{" "}
        <a href="https://blog.peppercloud.com/roles-hierarchy-and-access-controls-for-sales-organisations/">
          Create a role-based hierarchy
        </a>{" "}
        in your CRM software and define the data accessibility for each user by
        assigning the relevant role. Use this feature to provide data access
        down the hierarchy for users who manage multiple teams, sales
        territories etc.
      </>
    ),
    alt: "CRM profile tool",
    img: crmToolRole,
  },
  {
    title: "Profile-based permission",
    text: "The profile defines what a user can do in the CRM system. Specify whether a user can create, read, update or delete any of the records. The profile-based permission provides you with full control over who can import or export the data.",
    alt: "CRM role tool",
    img: crmToolProfile,
  },
  {
    title: "Permissions and visibility",
    text: "Create information hierarchies to protect the data on your company’s account. Open or restrict the record access at your company level. Set up the level of default data access for users within the CRM tool.",
    alt: "CRM security Access control",
    img: crmToolPermission,
  },
];

const client = {
  image: (
    <StaticImage
      alt="Pepper Cloud+Singapore Maritime Foundation"
      layout="constrained"
      src="../images/v2/testimonials-images/wilson-tan-peppercloud-customer.jpg"
    />
  ),
  name: "Wilson Tan",
  position: "Senior Manager",
  company: "Singapore Maritime Foundation, Singapore",
  content: (
    <React.Fragment>
      Pepper Cloud is a good CRM tool to improve coordination among SMF
      departments and improve our database management.
      <br />
      <br />
      The user-friendly interface, easy-to-use functions, and customisable
      features meant we could set up, integrate, and onboard the whole team on
      the cloud platform quickly.
      <br />
      <br />
      We appreciate the support and responsiveness of the Pepper Cloud team
      during the implementation process!
    </React.Fragment>
  ),
};

const Security = () => {
  const width = useWidth();
  const classes = useStyles();
  const [activeTwoFA, setActiveTwoFA] = React.useState(0);
  const [activeSafeGuard, setActiveSafeGuard] = React.useState(0);
  const [activeDataSecurity, setActiveDataSecurity] = React.useState(0);

  const twoFA = [
    {
      title: "Two-factor authentication",
      description:
        "Increase your CRM login security from any device with two-factor authentication. Enable or disable this additional level of security by sliding the toggle on or off. ",
    },
    {
      title: "Secure entire organisation",
      description: (
        <>
          Safeguard the data of your entire organisation by activating 2 Factor
          Authentication through administrator privileges. The{" "}
          <a href="https://peppercloud.com/">best sales CRM</a> provides you
          with the flexibility to control two-factor authentication at the user
          level.
        </>
      ),
    },
    {
      title: "Security code",
      description:
        "Make your CRM system immune to data security breaches. Use Google or Microsoft authenticator to generate the security code and safely access your data.",
    },
  ];

  const safeGuard = [
    {
      title: "Data encryption",
      description:
        "Protect your data with a strong and robust encryption method. With the state-of-the-art encryption technology of Pepper Cloud CRM software, we ensure the security of your data by encrypting it during transit and when at rest.",
    },
    {
      title: "Data backup",
      description:
        "Keep your data secure for a long time to come. Your data is regularly backed up in real-time across multiple servers to avoid the loss of data. We store and protect your data in world-class hosting infrastructure such as AWS cloud services.",
    },
    {
      title: "Data privacy",
      description:
        "Safeguard your customer data with the highest standards of data privacy practices. Pepper Cloud never uses your data beyond the intended services that you have opted in for.",
    },
  ];

  const dataSecurity = [
    {
      title: "Vulnerability assessment",
      description:
        "Be assured that your data is secure with Pepper Cloud CRM. We have been assessed and certified as a zero vulnerability CRM, by TUV.",
    },
    {
      title: "Penetration testing",
      description: (
        <>
          Never be worried about cybersecurity attacks. We ensure the holistic
          security of your data in your CRM which is tested by{" "}
          <a href="https://www.tuvsud.com/en-in">TUV SUD</a> and has been
          certified as free from harmful penetration attacks.
        </>
      ),
    },
  ];

  const security =
    `Shield misuse of data with a world-class data security feature.
Prevent any possible cyberattacks and eavesdropping on your data. 
	Focus on your business growth while your data is guarded by multi-layered security. 
	Maintain long-lasting, safe, and secure relationships with your customers.`
      .split(`
`);

  return (
    <React.Fragment>
      <SEO
        canonical="/crm-data-security-protection"
        description="Pepper Cloud is TUV-certified CRM software that comes with advanced CRM data security and protection features likes user permissions, single sign-on, two-factor authentication and more."
        keywords="CRM security,data security,data protection,customer data protection ,two-factor authentication,CRM privacy and security"
        ogData={{
          "og:image": [
            "/meta/og/1X1/security.png",
            "/meta/og/1024X512/security.png",
            "/meta/og/1200X630/security.png",
          ],
        }}
        pathname="/crm-data-security-protection"
        title="CRM Data Security and Protection Policies | Pepper Cloud"
      />
      <Box
        alignItems="center"
        className={classes.securityBannerBg}
        component={Grid}
        container
        justify="center"
        py={2}
      >
        <Grid item md={6} sm={12}>
          <Box
            alignItems="center"
            display="flex"
            height="100%"
            justifyContent={{ sm: "center", md: "flex-end" }}
          >
            <Box maxWidth={620} p={1}>
              <HeaderTypography
                component="h1"
                mb={4}
                mt={{ sm: 3, xs: 3 }[width]}
                textAlign={{ sm: "center", xs: "center" }[width] || "left"}
              >
                Your data security is our top priority
              </HeaderTypography>
              <ParagraphTypography
                mb={6}
                mr={4}
                textAlign={{ sm: "center", xs: "center" }[width] || "left"}
              >
                Focus on building your customer data rather than being worried
                about its security. Keep your data safe and secure with the
                robust, multi-layered security architecture of Pepper Cloud.
              </ParagraphTypography>
              <Box textAlign={{ sm: "center", xs: "center" }[width] || "left"}>
                <Button
                  className={classes.btn}
                  color="secondary"
                  component={Link}
                  size="large"
                  to="/contact"
                  variant="contained"
                >
                  Sign up now
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          {/* <Hidden implementation="css" smDown> */}
          <Box textAlign="right">
            <img
              alt="CRM security data points"
              className={classes.img}
              src={SecurityImg}
            />
          </Box>
          {/* </Hidden> */}
        </Grid>
      </Box>
      <Divider light />
      <Box mb={5} mt={5} />
      <Container>
        <Box display="flex" justifyContent="center">
          <Box maxWidth={1030} pb={3} textAlign="center">
            <HeaderTypography component="h2" mb={0}>
              Your data is in the right hands!
            </HeaderTypography>
            <ParagraphTypography fontSize={26} fontWeight={600} mt={2} pb={2}>
              Always safe. Always secured.
            </ParagraphTypography>
            <Box mb={5} textAlign="center">
              <img
                alt="Shield icon to indicate top CRM security"
                className={classes.img}
                src={alwaysSafeShield}
                width={192}
              />
            </Box>
            <ParagraphTypography fontSize={18} pb={2}>
              At Pepper Cloud, we understand the different aspects of data
              security and consider cyber threats seriously. With our
              multi-layer security and strict privacy controls, we assure data
              privacy and complete control over who accesses your data.
            </ParagraphTypography>
            <Box mb={{ md: 6 }} mt={4} textAlign="center">
              <Button
                className={classes.btn}
                color="secondary"
                component={Link}
                size="large"
                to="/contact"
                variant="contained"
              >
                Tell me how to do it?
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box mb={4} mt={2}>
        <Divider light />
      </Box>
      <Box component={Container}>
        <Box display="flex" justifyContent="center">
          <Box maxWidth={978} pb={{ md: 3 }}>
            <Box pb={6} textAlign="center">
              <HeaderTypography
                alignItems="center"
                component="h2"
                // fontSize={48}
                display="flex"
                justifyContent="center"
                lineHeight={1.2}
                mb={4}
              >
                100% safe and reliable data protection
              </HeaderTypography>
              <ParagraphTypography component="h3" fontSize={18}>
                Control data accessibility and be assured of customer data
                protection. Easy-to-use CRM security features help you control
                user access and equip you to keep your data safe and secure.
              </ParagraphTypography>
            </Box>

            <Grid container justify="center">
              {productBenefits.map((each) => (
                <Grid item key={each.text} md={6} sm={6}>
                  <Box mx={{ sm: 4, xs: 2 }[width] || 12}>
                    <Box
                      alt={each.alt}
                      component="img"
                      height={100}
                      src={each.img}
                    />
                    <HeaderTypography fontSize={20} mb={0}>
                      {each.title}
                    </HeaderTypography>
                    <ParagraphTypography
                      fontSize={16}
                      fontWeight={400}
                      mb={6}
                      mt={1}
                    >
                      {each.text}
                    </ParagraphTypography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box mb={6} mt={2}>
        <Divider light />
      </Box>
      <Container>
        <Grid alignItems="center" container justify="center">
          <Grid item md={6} sm={12}>
            <Box p={1}>
              <HeaderTypography
                alignItems="center"
                component="h2"
                display="flex"
                fontSize="32px"
                fontWeight={700}
                lineHeight={1.4}
                mb={{ md: 3 }}
              >
                Keep away intruders with multi-factor authentication security
              </HeaderTypography>
              <ParagraphTypography
                fontSize={18}
                mt={0}
                textAlign={{ sm: "center", xs: "center" }[width]}
              >
                Secure your account with easy-to-use, multi-factor
                authentication (MFA). Safeguard your organisation and your data
                with an additional level of security.
              </ParagraphTypography>
              <Box my={3}>
                <Divider light />
              </Box>
              {twoFA.map((each, index) => (
                <div key={each.title}>
                  <Box my={1} p={1}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                      onClick={() =>
                        setActiveTwoFA(index === activeTwoFA ? -1 : index)
                      }
                    >
                      <Box
                        color="text.secondary"
                        component="h3"
                        fontSize="h6.fontSize"
                        fontWeight="600"
                        m={0}
                      >
                        {each.title}
                      </Box>
                      <IconButton
                        aria-label="Expand or Collapse"
                        className={classes.expendButton}
                        color="default"
                      >
                        {index !== activeTwoFA ? (
                          <AddIcon fontSize="small" />
                        ) : (
                          <RemoveIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Box>
                    <Collapse in={activeTwoFA === index}>
                      <ParagraphTypography fontSize={16} mr={6} mt={2}>
                        {each.description}
                      </ParagraphTypography>
                    </Collapse>
                  </Box>
                  <Divider light />
                </div>
              ))}
            </Box>
            {/*</Box>*/}
          </Grid>
          <Grid item md={6} sm={12}>
            <img
              alt="Multifactor authentication for multi-layered CRM security"
              className={classes.img}
              src={securityTFA}
            />
          </Grid>
        </Grid>
      </Container>
      <Box mb={{ sm: 4, xs: 2 }[width] || 6} mt={{ sm: 4, xs: 2 }[width] || 8}>
        <Divider light />
      </Box>
      <Container>
        <Grid
          alignItems="center"
          container
          direction={{ sm: "column-reverse", xs: "column-reverse" }[width]}
          justify="center"
        >
          <Grid item md={6} sm={12}>
            <img
              alt="Safeguard shield icon - Data protection with data encryption technology"
              className={classes.img}
              src={securityRobust}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <Box p={1}>
              <HeaderTypography
                alignItems="center"
                component="h2"
                display="flex"
                fontSize="32px"
                fontWeight={700}
                lineHeight={1.4}
                mb={{ md: 3 }}
                textAlign={{ sm: "center", xs: "center" }[width] || "left"}
              >
                Safeguard Your Data with Robust Security Infrastructure
              </HeaderTypography>
              <ParagraphTypography
                mt={0}
                textAlign={{ sm: "center", xs: "center" }[width] || "left"}
              >
                Keep your information safe and secure across your organisation
                with powerful multi-tiered security infrastructure.
              </ParagraphTypography>
              <Box my={3}>
                <Divider light />
              </Box>
              {safeGuard.map((each, index) => (
                <div key={each.title}>
                  <Box my={1} p={1}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                      onClick={() =>
                        setActiveSafeGuard(
                          index === activeSafeGuard ? -1 : index
                        )
                      }
                    >
                      <Box
                        color="text.secondary"
                        component="h3"
                        fontSize="h6.fontSize"
                        fontWeight="600"
                        m={0}
                      >
                        {each.title}
                      </Box>
                      <IconButton
                        aria-label="Expand or Collapse"
                        className={classes.expendButton}
                        color="default"
                      >
                        {index !== activeSafeGuard ? (
                          <AddIcon fontSize="small" />
                        ) : (
                          <RemoveIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Box>
                    <Collapse in={activeSafeGuard === index}>
                      <ParagraphTypography fontSize={16} mr={6} mt={2}>
                        {each.description}
                      </ParagraphTypography>
                    </Collapse>
                  </Box>
                  <Divider light />
                </div>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box mb={6} mt={{ xs: 6, sm: 6, md: 8 }}>
        <Divider light />
      </Box>
      <Box className={classes.lightBlueBg}>
        <Container>
          <Grid alignItems="center" container justify="center">
            <Grid item md={6} sm={12}>
              <Box mb={11} p={1}>
                <HeaderTypography
                  alignItems="center"
                  component="h2"
                  display="flex"
                  fontSize="32px"
                  fontWeight={700}
                  lineHeight={1.4}
                  mb={3}
                  textAlign={{ sm: "center", xs: "center" }[width] || "left"}
                >
                  World-class TUV certified application
                </HeaderTypography>
                <ParagraphTypography
                  mt={0}
                  textAlign={{ sm: "center", xs: "center" }[width] || "left"}
                >
                  Guard your data and your peace of mind with our certified SaaS
                  cloud security.
                </ParagraphTypography>
                <Box my={3}>
                  <Divider light />
                </Box>
                {dataSecurity.map((each, index) => (
                  <div key={each.title}>
                    <Box my={1} p={1}>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                        onClick={() =>
                          setActiveDataSecurity(
                            index === activeDataSecurity ? -1 : index
                          )
                        }
                      >
                        <Box
                          color="text.secondary"
                          component="h3"
                          fontSize="h6.fontSize"
                          fontWeight="600"
                          m={0}
                        >
                          {each.title}
                        </Box>
                        <IconButton
                          aria-label="Expand or Collapse"
                          className={classes.expendButton}
                          color="default"
                        >
                          {index !== activeDataSecurity ? (
                            <AddIcon fontSize="small" />
                          ) : (
                            <RemoveIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Box>
                      <Collapse in={activeDataSecurity === index}>
                        <ParagraphTypography fontSize={16} mr={6} mt={2}>
                          {each.description}
                        </ParagraphTypography>
                      </Collapse>
                    </Box>
                    <Divider light />
                  </div>
                ))}
              </Box>
            </Grid>
            <Grid item md={6} sm={12}>
              <img
                alt="An intruder failing to breach security in TUV certified CRM application"
                className={classes.img}
                src={securityHacker}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
      </Box>
      {/* <Box mb={2} mt={0}>
				<Divider light />
			</Box> */}
      <Box component={Container}>
        <Box display="flex" justifyContent="center">
          <Box pb={3}>
            <Box pb={6} pt={4} textAlign="center">
              <HeaderTypography
                alignItems="center"
                component="h2"
                display="flex"
                justifyContent="center"
                lineHeight={1.2}
                mb={0}
                mt={6}
              >
                Added security for increased data protection
              </HeaderTypography>
            </Box>
            <Grid alignItems="center" container justify="center" spacing={2}>
              <Grid item md={6} sm={6} xs={12}>
                <List dense>
                  {security.map((each) => (
                    <ListItem key={each}>
                      <ListItemIcon>
                        <CheckIcon className={classes.listItemIcon} />
                      </ListItemIcon>
                      <ListItemText className={classes.listItemText}>
                        {each}
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
                <br />
                <br />
                <Box
                  textAlign={{ xs: "center", sm: "center" }[width] || "left"}
                >
                  <Button
                    className={classes.btn}
                    color="secondary"
                    component={Link}
                    size="large"
                    to="/contact"
                    variant="contained"
                  >
                    Tell me how to do it?
                  </Button>
                </Box>
              </Grid>
              <Grid item md={1} sm={1} />
              <Grid item md={5} sm={5} xs={12}>
                <img
                  alt="Shield icon - Protect your data through the top CRM security"
                  className={classes.img}
                  height="auto"
                  src={protectionShield}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box my={0}>
        <Divider light />
      </Box>
      <Box component={Container} textAlign="center">
        <Box py={8}>
          <HeaderTypography
            alignItems="center"
            display="flex"
            fontSize={40}
            justifyContent="center"
            lineHeight={1.2}
            mb={2}
            mt={0}
          >
            Know our customer&apos;s experience
          </HeaderTypography>
          <Box display="flex" justifyContent="center">
            <ParagraphTypography maxWidth={720}>
              Many businesses trust Pepper Cloud CRM software to run their
              operations globally. Know about our clients&apos; experiences in
              their words.
            </ParagraphTypography>
          </Box>
        </Box>
        <Container>
          <SingleTestimonial {...client} />
        </Container>
      </Box>
      <Box display="flex" justifyContent="center" mb={10} mt={10}>
        <Button
          className={classes.btn}
          color="secondary"
          component={Link}
          size="large"
          to="/contact"
          variant="contained"
        >
          Get Started
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default Security;
